import { Box, Typography } from '@drivekyte/ui';
import Image from 'next/image';
import NavBarLink from '../nav-bar-links/nav-bar-link';
import useTranslation from '@/hooks/use-translation';
import { FAQ_URL } from '@/config/global-constants';
import useAnalyticsWithBookingCart from '@/hooks/use-analytics-with-booking-cart-data';

const DesktopNavBarMenu = ({
  isAuthenticated = false,
  handlePressSignOut,
}: {
  isAuthenticated: boolean;
  handlePressSignOut: (event: React.MouseEvent) => void;
}) => {
  const { trackEvent, AnalyticsEvents } = useAnalyticsWithBookingCart();
  const { t } = useTranslation('components.header.navigation-v2');

  return (
    <Box
      width={260}
      paddingVertical="$tiny"
      backgroundColor="$backgroundPrimary"
      borderRadius="$large"
      style={{
        boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.14)',
      }}
      overflow="hidden"
    >
      {isAuthenticated && (
        <Box
          borderBottomWidth={1}
          borderBottomColor="$borderDefault"
          paddingBottom="$small"
        >
          <NavBarLink
            href="/account"
            onPress={() =>
              trackEvent(AnalyticsEvents.Navigation.NavAccountClicked, {
                hpExperimentNavUsed: true,
              })
            }
            prefetch
          >
            <Box
              paddingHorizontal="$medium"
              paddingVertical="$tiny"
              hoverStyle={{
                backgroundColor: '$backgroundTertiary',
              }}
              cursor="pointer"
            >
              <Typography variant="labelMedium" fontWeight="700">
                {t('my-account')}
              </Typography>
            </Box>
          </NavBarLink>
          <NavBarLink
            href="/invite-friends"
            onPress={() =>
              trackEvent(AnalyticsEvents.Navigation.NavReferralClicked, {
                hpExperimentNavUsed: true,
              })
            }
            prefetch
          >
            <Box
              paddingHorizontal="$medium"
              paddingVertical="$tiny"
              hoverStyle={{
                backgroundColor: '$backgroundTertiary',
              }}
              cursor="pointer"
            >
              <Typography variant="labelMedium" fontWeight="700">
                {t('invite-friends')}
              </Typography>
            </Box>
          </NavBarLink>
          <NavBarLink href="/" onPress={handlePressSignOut}>
            <Box
              paddingHorizontal="$medium"
              paddingVertical="$tiny"
              hoverStyle={{
                backgroundColor: '$backgroundTertiary',
              }}
              cursor="pointer"
            >
              <Typography variant="labelMedium" fontWeight="700">
                {t('logout')}
              </Typography>
            </Box>
          </NavBarLink>
        </Box>
      )}
      <Box
        borderBottomWidth={1}
        borderBottomColor="$borderDefault"
        paddingTop={isAuthenticated ? '$small' : 0}
        paddingBottom="$small"
      >
        {!isAuthenticated && (
          <NavBarLink
            href="https://help.kyte.com/en/articles/7052971-how-does-kyte-work"
            onPress={() =>
              trackEvent(AnalyticsEvents.Navigation.NavHowKyteWorksClicked, {
                hpExperimentNavUsed: true,
              })
            }
            prefetch
          >
            <Box
              paddingHorizontal="$medium"
              paddingVertical="$tiny"
              hoverStyle={{
                backgroundColor: '$backgroundTertiary',
              }}
              cursor="pointer"
            >
              <Typography variant="paragraphMedium">
                {t('how-kyte-works')}
              </Typography>
            </Box>
          </NavBarLink>
        )}
        <NavBarLink
          href={FAQ_URL}
          onPress={() =>
            trackEvent(AnalyticsEvents.Navigation.NavFaqClicked, {
              hpExperimentNavUsed: true,
            })
          }
          openNewWindow
          prefetch
        >
          <Box
            paddingHorizontal="$medium"
            paddingVertical="$tiny"
            hoverStyle={{
              backgroundColor: '$backgroundTertiary',
            }}
            cursor="pointer"
          >
            <Typography variant="paragraphMedium">
              {t('help-center')}
            </Typography>
          </Box>
        </NavBarLink>
        <NavBarLink
          href="https://try.drivekyte.com/driver-partner/"
          onPress={() =>
            trackEvent(AnalyticsEvents.Navigation.NavBecomeASurferClicked, {
              hpExperimentNavUsed: true,
            })
          }
        >
          <Box
            paddingHorizontal="$medium"
            paddingVertical="$tiny"
            hoverStyle={{
              backgroundColor: '$backgroundTertiary',
            }}
            cursor="pointer"
          >
            <Typography variant="paragraphMedium">
              {t('become-a-surfer')}
            </Typography>
          </Box>
        </NavBarLink>
      </Box>
      <Box paddingTop="$small">
        <Box
          paddingHorizontal="$medium"
          paddingBottom="$small"
          alignItems="center"
          rowGap="$small"
        >
          <Image
            src="/images/home-page/app_qr_code.png"
            width={100}
            height={100}
            alt="download app"
          />
          <Typography variant="paragraphSmall" color="$green600">
            {t('get-more-done-in-app')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default DesktopNavBarMenu;
