import useAnalyticsWithBookingCart from '@/hooks/use-analytics-with-booking-cart-data';
import useCustomerSupport from '@/hooks/use-customer-support';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import useTranslation from '@/hooks/use-translation';

const useOpenChatUrl = () => {
  const { showSupport, showNewMessage, showSupportArticle, SupportArticles } =
    useCustomerSupport();
  const router = useRouter();
  const { trackEvent, AnalyticsEvents } = useAnalyticsWithBookingCart();
  const { t } = useTranslation('pages.support');

  useEffect(() => {
    const supportType = router.query.open_support;
    const message: unknown = router.query.support_message;

    if (!router.query.open_support) {
      return;
    }

    trackEvent(AnalyticsEvents.Support.OpenUrl, { message, type: supportType });

    switch (supportType as string) {
      case 'chat':
        showNewMessage(message as string);
        break;
      case 'article':
        showSupportArticle(
          message as (typeof SupportArticles)[keyof typeof SupportArticles],
        );
        break;
      case 'help':
        showSupport();
        break;
    }
  });
  const metaTags = useMemo(() => {
    if (router.query.open_support) {
      return {
        description: t('page-description'),
        'og:description': t('page-description'),
        'og:title': t('page-title'),
        title: t('page-title'),
      };
    }
    return null;
  }, [router.query.open_support]);

  return { metaTags };
};

export default useOpenChatUrl;
