import { Box } from '@drivekyte/ui';
import { Place } from '@drivekyte/use-places-search';
import useAnalyticsWithBookingCart from '@/hooks/use-analytics-with-booking-cart-data';
import useSearch from '@/hooks/use-search';
import useTranslation from '@/hooks/use-translation';
import useSearchContext from '../../context/use-search-context';
import Divider from '../divider/divider';
import WhereInput from './components/where-input';

type WhereProps = {};

const Where = ({}: WhereProps) => {
  const { t } = useTranslation('pages.home-page.hero.search.where');
  const onInputBlur = useSearchContext((state) => state.onInputBlur);
  const { trackEvent, AnalyticsEvents } = useAnalyticsWithBookingCart();

  const {
    bookingCart,
    onChangeAddressToggle,
    onChangeEndAddress,
    onChangeStartAddress,
  } = useSearch({ disableQuoteFetching: true, disableTimeslotFetching: true });

  const handleChangeAddress = (address: Place, key: 'start' | 'end') => {
    trackEvent(AnalyticsEvents.BookingFlow.SearchAddressSubmitted, {
      address: address.display_text,
      is_start_leg: key === 'start',
      is_end_leg: key === 'end' || !!bookingCart.isSameDeliveryReturnLocations,
    });

    if (key === 'start') {
      onChangeStartAddress(address);
    } else {
      onChangeEndAddress(address);
    }

    onInputBlur();
  };

  const handleToggleSameAddress = (nextValue: boolean) => {
    onChangeAddressToggle(nextValue);
    onInputBlur();
  };

  return (
    <Box borderRadius={80} alignItems="flex-start">
      {bookingCart.isSameDeliveryReturnLocations ? (
        <WhereInput
          label={t('same-address.label')}
          placeholder={t('same-address.placeholder')}
          onSelect={(address) => handleChangeAddress(address, 'start')}
          initialInputValue={bookingCart.startPlace || bookingCart.startAddress}
          sameAddressToggle={{
            value: bookingCart.isSameDeliveryReturnLocations,
            onToggle: handleToggleSameAddress,
            label: t('same-address-toggle'),
          }}
          id="same-address"
          isStartLeg
          isEndLeg
        />
      ) : (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <WhereInput
            label={t('start-address.label')}
            placeholder={t('start-address.placeholder')}
            onSelect={(address) => handleChangeAddress(address, 'start')}
            initialInputValue={
              bookingCart.startPlace || bookingCart.startAddress
            }
            sameAddressToggle={{
              value: bookingCart.isSameDeliveryReturnLocations,
              onToggle: handleToggleSameAddress,
              label: t('same-address-toggle'),
            }}
            id="start-address"
            isStartLeg
          />
          <Divider />
          <WhereInput
            id="end-address"
            label={t('end-address.label')}
            placeholder={t('end-address.placeholder')}
            onSelect={(address) => handleChangeAddress(address, 'end')}
            initialInputValue={bookingCart.endPlace || bookingCart.endAddress}
            sameAddressToggle={{
              value: bookingCart.isSameDeliveryReturnLocations,
              onToggle: handleToggleSameAddress,
              label: t('same-address-toggle'),
            }}
            isEndLeg
          />
        </Box>
      )}
    </Box>
  );
};

export default Where;
