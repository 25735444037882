import HandoverOutpostConfirmationDialog from '@/components/handover-outpost-confirmation';
import useAnalyticsWithBookingCart from '@/hooks/use-analytics-with-booking-cart-data';
import useRentalSearchHistory from '@/hooks/use-rental-search-history';
import useSearch from '@/hooks/use-search';
import useTranslation from '@/hooks/use-translation';
import { Box, PillButton } from '@drivekyte/ui';
import Divider from './components/divider';
import When from './components/when';
import Where from './components/where';
import useSearchContext from './context/use-search-context';

type SearchProps = {
  onSubmitPress?: () => void;
  ctaText?: string;
};

const Search = ({ onSubmitPress = () => {}, ctaText }: SearchProps) => {
  const searchContext = useSearchContext();
  const { t } = useTranslation('pages.home-page.hero.search');
  const {
    onSubmitWithQuotes,
    isFormValid,
    handoverOutpostState,
    acceptHandoverSuggestion,
    rejectHandoverSuggestions,
    isFetchingQuotes,
    isLoadingTimeSlots,
  } = useSearch();
  const { saveSearchHistory } = useRentalSearchHistory();
  const { trackEvent, AnalyticsEvents } = useAnalyticsWithBookingCart();

  const handleOnPress = () => {
    saveSearchHistory();
    onSubmitWithQuotes();
    onSubmitPress();
  };

  return (
    <Box
      maxWidth={952}
      borderRadius={80}
      backgroundColor={
        searchContext.inputFocused
          ? '$backgroundTertiary'
          : '$backgroundPrimary'
      }
      shadowColor={'rgba(0, 0, 0, 0.14)'}
      shadowOffset={{ width: 0, height: 1 }}
      shadowRadius={8}
      display="flex"
      flexDirection="row"
      alignItems="center"
      onPress={() => trackEvent(AnalyticsEvents.BookingFlow.SearchBarClicked)}
    >
      <Where />
      <Divider />
      <When />
      <Box paddingHorizontal="$small">
        <PillButton
          alignSelf="center"
          onPress={handleOnPress}
          variant="primary"
          width={80}
          disabled={!isFormValid}
          loading={isFetchingQuotes || isLoadingTimeSlots}
          aria-label="search-button"
          role="button"
        >
          {ctaText ?? t('cta')}
        </PillButton>
      </Box>
      {handoverOutpostState.isConfirmationEnabled && (
        <HandoverOutpostConfirmationDialog
          visible
          handoverOutpostState={handoverOutpostState}
          onSubmit={acceptHandoverSuggestion}
          onClose={rejectHandoverSuggestions}
        />
      )}
    </Box>
  );
};

export default Search;
