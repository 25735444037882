import React, { useEffect, useRef } from 'react';
import { Box } from '@drivekyte/ui';
import useSearchContext from '@/pages/home-page/components/hero-section/components/hero-desktop/components/search/context/use-search-context';
import SearchCompactView from '@/pages/home-page/components/hero-section/components/hero-desktop/components/search/search-compact-view';
import Search from '@/pages/home-page/components/hero-section/components/hero-desktop/components/search';
import styled, { css } from 'styled-components';
import { useSearchbarProvider } from './search-bar-context';

const SearchBar = () => {
  const { isSearchbarOpen, toggleSearchbar } = useSearchbarProvider();
  const searchContext = useSearchContext();
  const searchRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target) &&
        !isDatePicker()?.contains(event.target) &&
        !isWhereSearch()?.contains(event.target)
      ) {
        toggleSearchbar(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        toggleSearchbar(false);
      }
    };

    document.addEventListener('keydown', handleEscKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [searchRef]);

  const handleCompactViewPress = () => {
    searchContext.onInputFocus('start-address');
    toggleSearchbar(true);
  };

  const isDatePicker = () => document.querySelector('[role="dialog"]');

  const isWhereSearch = () => document.querySelector('[role="listbox"]');

  return (
    <>
      {!isSearchbarOpen && (
        <SearchCompactView onPress={handleCompactViewPress} />
      )}
      {isSearchbarOpen && (
        <>
          <Box zIndex={11} ref={searchRef}>
            <Search onSubmitPress={() => toggleSearchbar(false)} />
          </Box>
          <Overlay />
        </>
      )}
    </>
  );
};

export default SearchBar;

const Overlay = styled.div`
  ${({}) => css`
    position: fixed;
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 10;
  `}
`;
