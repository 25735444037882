import React from 'react';
import Divider from '@/pages/home-page/components/hero-section/components/hero-desktop/components/search/components/divider';
import { Box, IconButton, Typography, useMedia } from '@drivekyte/ui';
import useSearch from '@/hooks/use-search';
import { ExpandMore } from '@drivekyte/icons';
import { useDateFormatter } from '@drivekyte/date-utils';

type SearchCompactViewProps = {
  onPress: () => void;
};

const SearchCompactView = ({ onPress }: SearchCompactViewProps) => {
  const media = useMedia();
  const { formatCustom } = useDateFormatter();
  const { bookingCart } = useSearch({
    disableQuoteFetching: true,
    disableTimeslotFetching: true,
  });
  const isMobile = !media.gtMd;

  const formatBookingDates = (startDate: Date, endDate: Date) => {
    const startMonth = formatCustom(startDate, 'MMM');
    const startDay = formatCustom(startDate, 'D');
    const endMonth = formatCustom(endDate, 'MMM');
    const endDay = formatCustom(endDate, 'D');

    const datesHaveSameMonth = startMonth === endMonth;

    return `${startMonth} ${startDay} - ${
      datesHaveSameMonth ? '' : `${endMonth} `
    }${endDay}`;
  };

  return (
    <Box
      maxWidth={952}
      borderRadius={80}
      backgroundColor="$backgroundPrimary"
      display="flex"
      borderStyle="solid"
      borderWidth={1}
      borderColor="$borderDefault"
      flexDirection="row"
      alignItems="center"
      cursor="pointer"
      onPress={onPress}
      height={52}
      bottom="$medium"
      $gtMd={{
        height: 'auto',
        bottom: 0,
      }}
      zIndex={1}
      style={{ position: isMobile ? 'fixed' : 'relative' }}
    >
      <Box maxWidth={212} padding="$small">
        <Typography
          variant="labelSmall"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {bookingCart.startPlace || bookingCart.startAddress}
        </Typography>
      </Box>
      <Divider />
      <Box
        padding="$small"
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
      >
        <Typography
          variant="labelSmall"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          alignSelf="center"
        >
          {formatBookingDates(bookingCart.startDate, bookingCart.endDate)}
        </Typography>
        <IconButton
          variant="tertiary"
          size="small"
          icon={ExpandMore}
          marginLeft="$small"
        />
      </Box>
    </Box>
  );
};

export default SearchCompactView;
