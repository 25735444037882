import React, { ReactNode, createContext, useContext, useState } from 'react';

type SearchbarToggleContextType = {
  isSearchbarOpen: boolean;
  toggleSearchbar: (value?: boolean) => void;
};

const SearchbarToggleContext = createContext<
  SearchbarToggleContextType | undefined
>(undefined);

type SearchbarToggleProviderProps = {
  children: ReactNode;
};

export const SearchbarToggleProvider = ({
  children,
}: SearchbarToggleProviderProps) => {
  const [isSearchbarOpen, setIsOpen] = useState(false);
  const toggleSearchbar = (value?: boolean) =>
    setIsOpen((prev) => value ?? !prev);

  return (
    <SearchbarToggleContext.Provider
      value={{ isSearchbarOpen, toggleSearchbar }}
    >
      {children}
    </SearchbarToggleContext.Provider>
  );
};

export const useSearchbarProvider = () => {
  const context = useContext(SearchbarToggleContext);
  if (!context) {
    throw new Error('useToggle must be used within a ToggleProvider');
  }
  return context;
};
