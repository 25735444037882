import { useState, useRef, useEffect } from 'react';
import { Box, Divider } from '@drivekyte/ui';
import { Illustrations } from '@drivekyte/web-components';
import DesktopNavBarMenu from './desktop-nav-bar-menu';
import Logo from '@/components/nav/components/logo';
import SearchBar from '../desktop-nav-bar-search/search-bar';
import { useNavbarContext } from '../nav-bar-context';
import { MyTripsButton } from './my-trips-button';
import DownloadAppButton from './download-app-button';
import LoginButton from './login-button';
import SignUpButton from './signup-button';
import { MenuButton } from './menu-button';

const DesktopNavBar = () => {
  const { isAuthenticated, handlePressSignOut, isDarkVariant } =
    useNavbarContext();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [menuRef]);

  return (
    <>
      <Box
        width="100%"
        zIndex={10}
        paddingLeft="$large"
        paddingRight="$small"
        paddingVertical="$medium"
        backgroundColor={isDarkVariant ? '$green700' : 'primaryB'}
      >
        <Box
          flexDirection="row"
          justifyContent="space-between"
          position="relative"
          alignItems="center"
          height="76px"
        >
          <Logo
            name={
              (isDarkVariant ? 'kyte-logo-white' : 'kyte-logo') as Illustrations
            }
          />

          {!isDarkVariant && <SearchBar />}

          <Box flexDirection="row">
            <Box
              flexDirection="row"
              alignItems="center"
              columnGap="$tiny"
              position="relative"
            >
              {isAuthenticated && isDarkVariant && (
                <>
                  <MyTripsButton />
                  <DownloadAppButton />
                </>
              )}
              {!isAuthenticated && isDarkVariant && (
                <>
                  <LoginButton />
                  <SignUpButton />
                </>
              )}
              <MenuButton handleMenuClick={handleMenuClick} />
              {menuOpen && (
                <Box
                  position="absolute"
                  top={56}
                  right={isAuthenticated ? 12 : 0}
                  ref={menuRef}
                >
                  <DesktopNavBarMenu
                    isAuthenticated={isAuthenticated}
                    handlePressSignOut={handlePressSignOut}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {!isDarkVariant && <Divider marginVertical="0" />}
    </>
  );
};

export default DesktopNavBar;
