import React, { createContext, useContext, ReactNode } from 'react';
import useTranslation from '@/hooks/use-translation';

type NavbarContextType = {
  isDarkVariant: boolean;
  isAuthenticated: boolean;
  handleSignUp: (event: React.MouseEvent) => void;
  handlePressSignOut: (event: React.MouseEvent) => void;
  t: (key: string, extraData?: {}) => string;
};

const NavbarContext = createContext<NavbarContextType | undefined>(undefined);

export const useNavbarContext = () => {
  const context = useContext(NavbarContext);
  if (context === undefined) {
    throw new Error('useNavbarContext must be used within a NavbarProvider');
  }
  return context;
};

type NavbarProviderProps = {
  children: ReactNode;
  isDarkVariant: boolean;
  isAuthenticated: boolean;
  handleSignUp: (event: React.MouseEvent) => void;
  handlePressSignOut: (event: React.MouseEvent) => void;
};

export const NavbarProvider: React.FC<NavbarProviderProps> = ({
  children,
  isDarkVariant = true,
  isAuthenticated,
  handleSignUp,
  handlePressSignOut,
}) => {
  const { t } = useTranslation('components.header.navigation-v2');

  return (
    <NavbarContext.Provider
      value={{
        isDarkVariant,
        isAuthenticated,
        handleSignUp,
        handlePressSignOut,
        t,
      }}
    >
      {children}
    </NavbarContext.Provider>
  );
};
