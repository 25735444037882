import {
  BreadcrumbItem,
  Breadcrumbs,
  Illustrations,
} from '@drivekyte/web-components';
import { Box, Divider } from '@drivekyte/ui';
import { Menu } from '@drivekyte/icons';
import Logo from '@/components/nav/components/logo';
import useBreadcrumbsRedirect from '@/hooks/use-breadcrumbs-redirect';
import { useNavbarContext } from '../nav-bar-context';

type MobileNavBarCollapsedProps = {
  onPress: () => void;
  subscriptionBreadcrumbs?: BreadcrumbItem[];
  top?: number;
};

const MobileNavBarCollapsed = ({
  onPress,
  subscriptionBreadcrumbs,
  top = 0,
}: MobileNavBarCollapsedProps) => {
  const handleRedirect = useBreadcrumbsRedirect();
  const { isDarkVariant } = useNavbarContext();

  return (
    <>
      <Box
        backgroundColor={isDarkVariant ? '$green700' : 'primaryB'}
        style={{
          position: isDarkVariant ? 'fixed' : 'relative',
        }}
        width="100%"
        justifyContent="center"
        zIndex={5}
        paddingHorizontal="$medium"
        paddingVertical="$medium"
        top={top > 0 ? top : 0}
      >
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Logo
            name={
              (isDarkVariant ? 'kyte-logo-white' : 'kyte-logo') as Illustrations
            }
          />
          <Box
            cursor="pointer"
            onPress={onPress}
            testID="navigation-collapsed-open"
          >
            <Menu
              color={
                isDarkVariant ? '$contentInversePrimary' : '$contentPrimary'
              }
            />
          </Box>
        </Box>
        {subscriptionBreadcrumbs && (
          <Box marginBottom="small">
            <Breadcrumbs
              items={subscriptionBreadcrumbs}
              onPress={handleRedirect}
            />
          </Box>
        )}
      </Box>
      {!isDarkVariant && <Divider marginVertical="0" />}
    </>
  );
};

export default MobileNavBarCollapsed;
