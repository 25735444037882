import React, { useState } from 'react';
import { useMedia } from '@drivekyte/ui';
import useAuthentication from '@/hooks/use-authentication';
import useSignOut from '@/hooks/use-sign-out';
import DesktopNavBar from './desktop-nav-bar/desktop-nav-bar';
import useAnalyticsWithSource from '@/hooks/use-analytics-with-source';
import useMe from '@/hooks/use-me';
// import MobileNavBarExpanded from './mobile-nav-bar/mobile-nav-bar-expanded';
import MobileNavBarCollapsed from './mobile-nav-bar/mobile-nav-bar-collapsed';
import useScroll from '@/hooks/use-scroll';
import { NavbarVariantEnum } from '@/constants/navbar';
import { NavbarProvider } from './nav-bar-context';
import dynamic from 'next/dynamic';

const MobileNavBarExpanded = dynamic(() =>
  import('./mobile-nav-bar/mobile-nav-bar-expanded'),
);

type NavbarProps = {
  variant?: NavbarVariantEnum;
};

const NavBar = ({ variant = NavbarVariantEnum.DARK_DEFAULT }: NavbarProps) => {
  const media = useMedia();
  const handleAuth = useAuthentication();
  const { trackEvent } = useAnalyticsWithSource();
  const signOutMutation = useSignOut();
  const { isAuthenticated } = useMe();
  const [expanded, setExpanded] = useState(false);
  const handlePress = () => setExpanded((currentValue) => !currentValue);
  const [top, setTop] = useState(0);
  const isDarkVariant = variant === NavbarVariantEnum.DARK_DEFAULT;

  const handlePressSignOut = (event: React.MouseEvent) => {
    event.preventDefault();
    trackEvent('nav:sign_out_button:clicked');
    signOutMutation.mutate();
  };

  const handleSignUp = (event: React.MouseEvent) => {
    event.preventDefault();
    trackEvent('nav:sign_up_button:clicked');
    handleAuth();
  };

  const checkBranchBanner = (scrollPosition: number) => {
    let branchBanner: HTMLElement | null = null;
    branchBanner = document.getElementById('branch-banner-iframe');
    if (branchBanner && scrollPosition < 30) {
      const height = branchBanner.clientHeight;
      setTop(height);
    } else {
      setTop(0);
    }
  };

  useScroll(checkBranchBanner, true);

  return (
    <NavbarProvider
      isAuthenticated={isAuthenticated}
      handleSignUp={handleSignUp}
      handlePressSignOut={handlePressSignOut}
      isDarkVariant={isDarkVariant}
    >
      <nav>
        <>
          {media.gtMd && <DesktopNavBar />}
          {!media.gtMd &&
            (expanded ? (
              <MobileNavBarExpanded onPress={handlePress} top={top} />
            ) : (
              <MobileNavBarCollapsed onPress={handlePress} top={top} />
            ))}
        </>
      </nav>
    </NavbarProvider>
  );
};

export default NavBar;
