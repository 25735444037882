import { useEffect, useRef, useState } from 'react';
import {
  AutoComplete,
  AutoCompleteItem,
  Box,
  Switch,
  Typography,
  getTokenValue,
} from '@drivekyte/ui';
import mapAutocompleteData from '@/components/search-bar/components/input-address/utils/mapAutocompleteData';
import SearchEmptyState from '@/components/search-empty-state';
import RecentSearches from '@/components/recent-searches';
import useOutsideServiceAreaExperiment from '@/hooks/experiments/use-outside-service-area-experiment/use-outside-service-area-experiment';
import useAnalyticsWithBookingCart from '@/hooks/use-analytics-with-booking-cart-data';
import { ServiceAreaLabel } from '@/hooks/use-service-area-labels';
import useRentalSearchHistory from '@/hooks/use-rental-search-history';
import useEnableRecentSearches from '@/hooks/use-enable-recent-searches';
import usePlacesSearch, { Place } from '@/hooks/use-places-search-wrapper';
import useSearchContext from '../../../../context/use-search-context';
import SearchInput from '../../../search-input';
import useVagueToLotRedirect from '@/hooks/use-vague-to-lot-redirect';

type WhereInputProps = {
  id: string;
  label: string;
  placeholder?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  onSelect: (address: Place) => void;

  initialInputValue: string;
  sameAddressToggle: {
    value: boolean;
    onToggle: (value: boolean) => void;
    label: string;
  };
  testID?: string;
  isStartLeg?: boolean;
  isEndLeg?: boolean;
};

const WhereInput = ({
  id,
  label,
  placeholder,
  initialInputValue,
  onSelect,
  testID,
  sameAddressToggle,
  isStartLeg,
  isEndLeg,
}: WhereInputProps) => {
  const [inputValue, setInputValue] = useState<string>(initialInputValue);
  const [isInputValueSelected, setIsInputValueSelected] =
    useState<boolean>(false);
  const [selectedServiceArea, setSelectedServiceArea] = useState<string | null>(
    null,
  );
  const [outsideServiceArea, setOutsideServiceArea] = useState<boolean>(false);
  // TODO: Remove this when the experiment has concluded: https://app.posthog.com/experiments/4986
  const { isOutsideServiceAreaExperimentTreatment } =
    useOutsideServiceAreaExperiment();

  const isNotInitialValue = inputValue !== initialInputValue;
  const placesSearchValue =
    isNotInitialValue && !outsideServiceArea ? inputValue : '';
  const { isLoading, isIdle, data } = usePlacesSearch(placesSearchValue);

  const { data: enableRecentSearches } = useEnableRecentSearches();
  const { enableAddressToggler } = useVagueToLotRedirect();

  const { trackEvent, AnalyticsEvents } = useAnalyticsWithBookingCart();
  const { fetchSearchHistory } = useRentalSearchHistory();
  const { totalHistoryItems } = fetchSearchHistory();

  useEffect(() => {
    //Force value update if the address value is changed by the parent component
    setInputValue(initialInputValue);
  }, [initialInputValue]);

  useEffect(() => {
    if (inputValue.length) return;
    setIsInputValueSelected(false);
  }, [inputValue]);

  const hasData = !isLoading && data && data.length > 0;

  useEffect(() => {
    if (hasData && !!selectedServiceArea) {
      setSelectedServiceArea(null);
      const serviceAreaPlace = data.find(
        (place) => place.place_type === 'city',
      );
      if (serviceAreaPlace) onSelect(serviceAreaPlace);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasData, selectedServiceArea]);

  const handleSelect = (autoCompleteItem: AutoCompleteItem<Place> | null) => {
    trackEvent(AnalyticsEvents.Search.SuggestionClicked, {
      search_input: inputValue,
      suggestion_text: autoCompleteItem?.value,
      address: autoCompleteItem?.extraData?.formatted_address,
      element_id: testID,
      service_area: autoCompleteItem?.extraData?.service_area_code,
      is_covered_location: autoCompleteItem?.extraData?.is_covered_location,
      place_type: autoCompleteItem?.extraData?.place_type,
      initial_input: initialInputValue,
      place_lat: autoCompleteItem?.extraData?.lat,
      place_lng: autoCompleteItem?.extraData?.lng,
      outside_service_area_exp: isOutsideServiceAreaExperimentTreatment,
    });
    if (autoCompleteItem?.extraData?.is_covered_location === false) {
      setInputValue(autoCompleteItem.value);
      setOutsideServiceArea(true);
      return;
    }
    if (autoCompleteItem?.value && autoCompleteItem?.extraData) {
      setInputValue(autoCompleteItem.value);
      onSelect(autoCompleteItem.extraData);
    }
    searchContext.onInputFocus(id);
    setIsInputValueSelected(true);
  };

  const handleSelectedServiceArea = (item: ServiceAreaLabel) => {
    setOutsideServiceArea(false);
    setSelectedServiceArea(item.code);
    trackEvent(AnalyticsEvents.Search.CityTagSelected, {
      serviceArea: item.code,
      testID,
      searchValue: inputValue,
      is_edit_trip: false,
      outside_service_area_exp: isOutsideServiceAreaExperimentTreatment,
    });
    setInputValue(item.cityAddress);
  };

  const handleInputChange = (newInputValue: string) => {
    if (newInputValue !== inputValue) {
      if (outsideServiceArea) {
        setOutsideServiceArea(false);
      }
      if (selectedServiceArea) {
        setSelectedServiceArea(null);
      }
      setInputValue(newInputValue);
    }
  };

  const autoCompleteData = mapAutocompleteData(
    data?.filter(
      (place) =>
        place.is_covered_location || isOutsideServiceAreaExperimentTreatment,
    ) ?? [],
  );

  const inputRef = useRef<HTMLInputElement>(null);
  const searchContext = useSearchContext();

  const focusInput = () => {
    inputRef.current?.focus();
  };

  const isFocused = searchContext.inputFocused === id;
  const showAutoCompleteDropdown =
    isFocused && isNotInitialValue && (!isIdle || outsideServiceArea);

  const showAutoCompleteRecentSearches = !!totalHistoryItems
    ? isFocused &&
      !isInputValueSelected &&
      !showAutoCompleteDropdown &&
      !!enableRecentSearches?.enabled
    : false;

  const showAutoComplete =
    showAutoCompleteRecentSearches || showAutoCompleteDropdown;

  const handleOnClose = () => searchContext.onInputBlur();
  const handleOnFocus = () => {
    if (!!initialInputValue) {
      inputRef.current?.select();
    }

    searchContext.onInputFocus(id);
  };
  const handleOnBlur = () => {
    if (!showAutoComplete) searchContext.onInputBlur();
  };
  const handleOuterPress = () => searchContext.onInputBlur();

  useEffect(() => {
    if (placesSearchValue && autoCompleteData.length) {
      trackEvent(AnalyticsEvents.BookingFlow.SearchAddressType, {
        address: placesSearchValue,
        is_start_leg: !!isStartLeg,
        is_end_leg: !!isEndLeg,
      });
    }
  }, [JSON.stringify(autoCompleteData)]);

  return (
    <Box width={260}>
      <AutoComplete<Place>
        open={showAutoComplete}
        inputValue={inputValue}
        loading={isLoading}
        onInputValueChange={handleInputChange}
        onOuterPress={handleOuterPress}
        testID={testID}
        data={autoCompleteData}
        onSelect={handleSelect}
        customEmptyState={
          <Box padding="base" paddingHorizontal="$medium">
            {showAutoCompleteRecentSearches ? (
              <RecentSearches onClose={handleOnClose} />
            ) : (
              <SearchEmptyState
                onServiceAreaItemPress={handleSelectedServiceArea}
                hideServiceAreas={false}
                outsideServiceArea={outsideServiceArea}
              />
            )}
          </Box>
        }
        renderInput={(inputOptions) => (
          <SearchInput
            inputOptions={inputOptions}
            isFocused={isFocused}
            label={label}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
            onPressLabel={focusInput}
            placeholder={placeholder}
            ref={inputRef}
          />
        )}
        containerStyle={{
          borderRadius: '$xxLarge',
          width: 420,
          backgroundColor: '$backgroundPrimary',
          shadowColor: 'rgba(0, 0, 0, 0.14)',
          shadowOffset: { width: 0, height: 1 },
          shadowRadius: 8,
          left: 0,
          top: 72,
          paddingVertical: showAutoComplete ? '$medium' : 0,
          paddingTop: showAutoComplete ? '$large' : 0,
        }}
        itemStyle={{
          paddingRight: `${getTokenValue('$medium', 'space')}px`,
          paddingLeft: `${getTokenValue('$medium', 'space')}px`,
        }}
        loadingContainerStyle={{
          paddingHorizontal: '$medium',
        }}
        listFooterComponent={() => (
          <Box
            marginTop="$medium"
            display="flex"
            flexDirection="row"
            paddingHorizontal="$medium"
          >
            <Switch
              value={sameAddressToggle.value}
              onChange={sameAddressToggle.onToggle}
              size="small"
              disabled={!enableAddressToggler}
            />
            <Box marginLeft="$xSmall">
              <Typography variant="paragraphSmall">
                {sameAddressToggle.label}
              </Typography>
            </Box>
          </Box>
        )}
      />
    </Box>
  );
};

export default WhereInput;
